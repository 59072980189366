.container-project-page {
  display: flex;
  position: absolute;
  left: 10%;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
}

.heading {
  margin-top: 100px;
  text-align: center;
  transform: translateY(-50%);
  vertical-align: middle;
  height: fit-content;

  h1 {
    font-size: 56px;
    font-family: "Coolvetica";
    color: #ffd700;
    font-weight: 400;
  }
}
.text-animate-hover {
  &:hover {
    color: #fff;
  }
}

.project-card {
  position: relative;
  padding: 25px;
  margin: 15px;
  height: 250px;
  width: 300px;
  background-color: rgb(15, 15, 14);
  border: 1px white solid;
  border-radius: 10%;
  opacity: 0;
  animation: fadeInUp 2s 2s;
  animation-fill-mode: forwards;
}

.lang-used {
  font-size: 14px;
  color: white;
}

.text-zone {
  color: white;
  line-height: 30px;
  letter-spacing: 1.4px;
}

.text-zone:hover {
  cursor: default;
}

.project-date {
  font-size: 15px;
  color: rgb(189, 189, 12);
}

.project-name a {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.project-description {
  height: 90px;
  font-size: 16px;
  color: rgb(176, 174, 174);
  overflow: auto;
}

.btn {
  display: block;
  bottom: 20px;
  left: 0;
}

.btn button:hover {
  cursor: pointer;
}

.btn {
  button {
    letter-spacing: 1.5px;
    font-size: medium;
    font-weight: 600;
    height: 35px;
  }
}
