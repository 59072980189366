.page {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: auto;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: "La Belle Aurore";
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    height: 90%;
    will-change: contents;
    position: absolute;
    min-height: 566px;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    ;
}

.about-page, .contact-page{
    .text-zone {
        position: absolute;
        left: 10%;
        top: 40%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 56px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 100px;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            &:before {
                font-family: 'La Belle Aurore', cursive;
                color: #ffd700;
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &:after {
                font-family: 'La Belle Aurore', cursive;
                color: #ffd700;
                font-size: 18px;
                line-height: 18px;
                position: absolute;
                left: -30px;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.6;
            }
        }

        p {
           
            font-size: 15px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            max-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                
                animation-delay: 2.5s;
                
            }

            &:nth-of-type(2) {
                animation-delay: 2.6s;
             
            }

            &:nth-of-type(3) {
                animation-delay: 2.7s;
               
            }
        }

        .text-zone {
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 35%;
            vertical-align: middle;
            display: table-cell;
            max-height: 90%;
        }

        .text-animate-hover {
            &:hover {
                color: #fff;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .page {
      position: initial;
    }
  
    .container {
      position: initial;
      height: auto;
      min-height: auto;
  
      &.contact-page,
      &.about-page,
      &.project-page {
        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 20px;
          box-sizing: border-box;
        }
      }
  
      &.project-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
  
        h1.page-title {
          margin-left: 20px;
          margin-top: 20px;
        }
  
        .image-box {
          height: 200px;
          max-width: calc(50% - 10px);
        }
      }
    }
  
  
  }